import React from "react";

import Page from "lib/@cms/components/layout/Page";
import { FormApplicationTemplate } from "lib/@forms/components/primitive";
import MEMBERSHIP_APPLICATION_TEMPLATE from "data/forms-templates/membership-application.json";

export default function MembershipApplicationPage() {
  return (
    <Page
      cms={{
        pageData: {
          MetaData: { title: "Membership Application", noRobots: true },
        },
      }}
      pathname="membership-application"
    >
      {() => <Form />}
    </Page>
  );
}

// --- Components ---

export function Form() {
  return (
    <FormApplicationTemplate
      data={MEMBERSHIP_APPLICATION_TEMPLATE}
      securedFormFriendlyName={process.env.GATSBY_SECURED_FORM_MEMBERSHIP_APPLICATION_NAME}
    />
  );
}
